import React, { memo, useCallback, useEffect, useRef } from "react";
import Input from "components/ui/Input";
import cn from "classnames";
import { isNil } from "lodash";
import PropertyErrors from "./PropertyErrors";
import PropertyDescription from "./PropertyDescription";
import { useDefaultValue, useShowError } from "components/ui/PortalBuilder/utils";
import { PropertyTitle, PropertyTitleWithSwitch } from "./PropertyTitle";
import { isPropertyRequired } from "components/ui/PortalBuilder/utils/validation";
import { PropertyListItem } from "./PropertyListItem";

import "./TextProperty.scss";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const TextProperty = memo((props) => {
    const {
        title,
        id,
        value,
        description,
        maxLength,
        onChange,
        fullWidth,
        defaultValue,
        borderTop,
        borderBottom,
        validationRules,
        errors = [],
        listContainsActiveError,
        infoIconText,
        size,
    } = props;
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);
    let disabled = props.disabled;
    const required = isPropertyRequired(validationRules);
    let switchId;
    let switchTitle;
    let switchValue;

    const elementRef = useRef(null);

    if (id === "cookiesBannerLinkUrl") {
        if (updatedConfig?.settings?.cookiesPageLink === "internal") {
            disabled = true;
        }
    }

    useEffect(() => {
        if (id === "cookiesBannerLinkUrl") {
            if (updatedConfig?.settings?.cookiesPageLink === "internal") {
                onChange(id, defaultValue);
            }
        }
    }, [id, defaultValue, onChange, value, updatedConfig?.settings?.cookiesPageLink]);

    const lightUpError = useShowError(elementRef, listContainsActiveError, id);

    if (!isNil(props.titleSwitch)) {
        switchId = props.titleSwitch.id;
        switchTitle = props.titleSwitch.title;
        switchValue = props.titleSwitchValue;
    }

    const onPropertyChange = useCallback(
        (event) => {
            const value = event.target.value;
            onChange(id, value.trim() === "" ? "" : value);
        },
        [id, onChange]
    );

    useDefaultValue(id, value, defaultValue, onChange);

    const innerClasses = cn("property-list-item-inner align-center", {
        "flex-column full-width-property": fullWidth,
        "flex-row justify-space-between": !fullWidth,
    });

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--text" borderTop={borderTop} borderBottom={borderBottom} size={size}>
            <div ref={elementRef} className={innerClasses}>
                {switchId && (
                    <PropertyTitleWithSwitch
                        title={title}
                        required={required}
                        switchId={switchId}
                        switchTitle={switchTitle}
                        switchValue={switchValue}
                        onChange={onChange}
                    />
                )}
                {!switchId && title && <PropertyTitle title={title} required={required} infoIconText={infoIconText} />}
                <Input
                    error={errors.length > 0}
                    lightUpError={lightUpError}
                    className="property-list-item-value"
                    value={value ?? ""}
                    onChange={onPropertyChange}
                    disabled={disabled}
                    maxLength={maxLength}
                    data-test-id={`${id}-input`}
                />
            </div>
            <PropertyDescription id={id} value={value} description={description} />
            <PropertyErrors errors={errors} />
        </PropertyListItem>
    );
});
