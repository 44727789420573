import React from "react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "schadcn/lib/utils";
import { Button } from "schadcn/components/ui/button";
import { Calendar } from "schadcn/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "schadcn/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "schadcn/components/ui/select";
import { DateRange as DayPickerRange } from "react-day-picker";
import { dateToJson } from "components/utils/date";
import { DateRange } from "../types";
import "./styles.scss";

export const ANALYTICS_DATE_RANGE_KEY = 'portal-analytics-date-range';

export const clearAnalyticsDateRange = () => {
    localStorage.removeItem(ANALYTICS_DATE_RANGE_KEY);
};

export const DateSelector = ({ dateRange, disabled, onDateRangeChange, includeCustomRange, onRangeChange, selectedRange: initialSelectedRange, setCustomRange }: DateSelectorProps) => {
    const [selectedRange, setSelectedRange] = React.useState<DayPickerRange | undefined>(() => {
        if (initialSelectedRange) {
            return initialSelectedRange;
        }
        const savedRange = localStorage.getItem(ANALYTICS_DATE_RANGE_KEY);
        if (savedRange) {
            const parsed = JSON.parse(savedRange);
            return parsed.selectedRange ? {
                from: parsed.selectedRange.from ? new Date(parsed.selectedRange.from) : undefined,
                to: parsed.selectedRange.to ? new Date(parsed.selectedRange.to) : undefined
            } : undefined;
        }
        const dates = getDateRange(dateRange);
        return {
            from: new Date(dates.dateFrom),
            to: new Date(dates.dateTo)
        };
    });
    const [tempRange, setTempRange] = React.useState<DayPickerRange | undefined>(selectedRange);
    const [currentRange, setCurrentRange] = React.useState<DateRangeOption>(() => {
        const savedRange = localStorage.getItem(ANALYTICS_DATE_RANGE_KEY);
        if (savedRange) {
            const parsed = JSON.parse(savedRange);
            return parsed.currentRange || dateRange;
        }
        return dateRange;
    });
    const [isOpen, setIsOpen] = React.useState(false);

    const dateRangeItems = Object.values(DateRanges)
        .map((range) => ({
            value: range,
            label: range,
        }))
        .filter((range) => includeCustomRange || (range.value !== DateRanges.Custom));

    const handlePresetChange = (value: string) => {
        const newRange = value as DateRangeOption;
        setCurrentRange(newRange);
        localStorage.setItem(ANALYTICS_DATE_RANGE_KEY, JSON.stringify({
            currentRange: newRange,
            selectedRange: undefined
        }));
        if (newRange === DateRanges.DatePicker) {
            setIsOpen(true);
            setTempRange(selectedRange);
        } else {
            setSelectedRange(undefined);
            setTempRange(undefined);
            onDateRangeChange(newRange);
            setIsOpen(false);
        }
    };

    const handleRangeSelect = (range: DayPickerRange | undefined) => {
        if (currentRange !== DateRanges.DatePicker) {
            return;
        }
        
        if (range?.from && range?.to) {
            const fromTime = range.from.getTime();
            const toTime = range.to.getTime();
            
            if (fromTime > toTime) {
                const temp = range.from;
                range.from = range.to;
                range.to = temp;
            }
        }
        
        setTempRange(range);
    };

    const handleApplyRange = () => {
        if (!tempRange?.from || !tempRange?.to) {
            return;
        }
        onRangeChange?.(tempRange);
        setSelectedRange(tempRange);
        localStorage.setItem(ANALYTICS_DATE_RANGE_KEY, JSON.stringify({
            currentRange: currentRange,
            selectedRange: {
                from: tempRange.from.toISOString(),
                to: tempRange.to.toISOString()
            }
        }));
        
        const dateRange: DateRange = {
            dateFrom: dateToJson(tempRange.from)!,
            dateTo: dateToJson(new Date(tempRange.to.setHours(23, 59, 59, 999)))!
        };
        setCustomRange?.(dateRange);
        onDateRangeChange(DateRanges.DatePicker);
        setIsOpen(false);
    };

    const handleCancel = () => {
        setTempRange(selectedRange);
        setIsOpen(false);
    };

    const displayText = React.useMemo(() => {
        if (currentRange === DateRanges.DatePicker) {
            if (selectedRange?.from && selectedRange?.to) {
                return `${format(selectedRange.from, "MMM dd, yyyy")} - ${format(selectedRange.to, "MMM dd, yyyy")}`;
            }
            return "Custom Range";
        }
        return dateRangeItems.find(item => item.value === currentRange)?.label || "Select Range";
    }, [currentRange, selectedRange, dateRangeItems]);

    const calculatedRange = React.useMemo(() => {
        const range = getDateRange(currentRange);
        return {
            from: new Date(range.dateFrom),
            to: new Date(range.dateTo)
        };
    }, [currentRange]);

    const today = React.useMemo(() => {
        const date = new Date();
        date.setHours(23, 59, 59, 999);
        return date;
    }, []);

    const fromDate = React.useMemo(() => {
        return new Date(2022, 11, 1);
    }, []);

    const defaultMonth = React.useMemo(() => {
        if (currentRange === DateRanges.AllTime) {
            return today;
        }
        return calculatedRange.from;
    }, [currentRange, calculatedRange.from, today]);

    return (
        <div className={cn("analytics-date-range-dropdown", { "field-group": true })}>
            <label className="!mb-0">Date Range:</label>
            <div className="dropdown-wrap">
                <Popover open={isOpen} onOpenChange={setIsOpen}>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            className="w-[250px] justify-start text-left font-normal"
                            disabled={disabled}
                        >
                            <FontAwesomeIcon icon={["far", "calendar"]} className="mr-2 h-4 w-4" />
                            {displayText}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent sideOffset={8} className="w-auto p-4 z-[100]" align="end" side="bottom">
                        <div className="space-y-4">
                            <Select
                                value={currentRange}
                                onValueChange={handlePresetChange}
                                disabled={disabled}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select range" />
                                </SelectTrigger>
                                <SelectContent className="z-[100]">
                                    {dateRangeItems.map((item) => (
                                        <SelectItem key={item.value} value={item.value}>
                                            {item.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            <div className="rounded-md border">
                                <Calendar
                                    initialFocus
                                    mode="range"
                                    defaultMonth={defaultMonth}
                                    selected={currentRange === DateRanges.DatePicker ? tempRange : calculatedRange}
                                    onSelect={handleRangeSelect}
                                    numberOfMonths={2}
                                    disabled={(date) => 
                                        date > today || 
                                        date < fromDate ||
                                        (currentRange !== DateRanges.DatePicker)
                                    }
                                    fromDate={fromDate}
                                    toDate={today}
                                />
                            </div>
                            {currentRange === DateRanges.DatePicker && (
                                <div className="flex justify-end space-x-2">
                                    <Button
                                        variant="outline"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleApplyRange}
                                        disabled={!tempRange?.from || !tempRange?.to}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            )}
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
        </div>
    );
};

export const getDateRange = (dateRange: DateRangeOption, customRange?: DateRange): DateRange => {
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    let dateFrom = new Date(currentDate);
    let dateTo = new Date(new Date().setHours(23, 59, 59, 999));

    switch (dateRange) {
        case DateRanges.Today:
            break;
        case DateRanges.Last7Days:
            dateFrom.setDate(currentDay - 7);
            break;
        case DateRanges.Last30Days:
            dateFrom.setDate(currentDay - 30);
            break;
        case DateRanges.MonthToDate:
            dateFrom.setDate(1);
            break;
        case DateRanges.LastMonth:
            if (currentMonth === 0) {
                dateFrom.setFullYear(currentYear - 1);
            }
            dateFrom.setMonth(currentMonth === 0 ? 11 : currentMonth - 1, 1);
            dateTo.setMonth(currentMonth, 0);
            break;
        case DateRanges.YearToDate:
            dateFrom.setMonth(0, 1);
            break;
        case DateRanges.Last12Months:
            dateFrom.setFullYear(currentYear - 1);
            dateFrom.setMonth(currentMonth + 1, 1);
            break;
        case DateRanges.AllTime:
            dateFrom.setFullYear(2022, 11, 1);
            break;
        case DateRanges.DatePicker:
            if(customRange) {
                dateFrom = new Date(customRange.dateFrom);
                dateTo = new Date(customRange.dateTo);
            }
            break;
        default:
            break;
    }

    return {
        dateFrom: dateToJson(dateFrom)!,
        dateTo: dateToJson(dateTo)!,
    };
};

interface DateSelectorProps {
    dateRange: DateRangeOption;
    disabled?: boolean;
    includeCustomRange?: boolean;
    onDateRangeChange: (value: DateRangeOption) => void;
    setCustomRange?: (value: DateRange) => void;
    selectedRange?: DayPickerRange | undefined;
    onRangeChange?: (value: DayPickerRange | undefined) => void;
}

export const DateRanges = {
    Today: "Today",
    Last7Days: "Last 7 days",
    Last30Days: "Last 30 days",
    MonthToDate: "Month to Date",
    LastMonth: "Last Month",
    YearToDate: "Year to Date",
    Last12Months: "Last 12 months",
    AllTime: "All Time",
    DatePicker: "Custom Range",
    Custom: "Custom",
} as const;

export type DateRangeOption = typeof DateRanges[keyof typeof DateRanges];
